$(() => {
  function getDocumentChildCategories(selected_parent_category_url) {
    return $.ajax({
      url: '/document_child_category' + '?parent_category_url=' + selected_parent_category_url,
      dataType: 'json',
      type: 'GET',
    });
  };

  $('#parent_category_url').change(function(){
    selected_parent_category_url = $(this).val()
    getDocumentChildCategories(selected_parent_category_url).done(function(result) {
      // 資料サブカテゴリを全取得
      document_child_categories = result;

      $select = $('#child_category_url'); 
      $option = [$('<option value="">サブカテゴリー</option>')]
      $.each(document_child_categories, function(idx, child_category) {
        $option.push($('<option>').val(child_category.url).text(child_category.name))
      });
      $select.children().remove()
      $select.append($option)
    });
  })
});